// export const text =
// {
//     Lower_Price: "Enter here lower price range in which you want to buy / sell stock. Used to take the trade when the stock comes above this range", 
//     Higher_Price: "Enter here higher price range in which you want to buy / sell stock. Used to take the trade when the stock comes below this range",
//     Unique_Name: "When we want to take multiple entries for the same stock, use different Unique Name.",
//     Trade_Count: "It is used to take multiple trades on the same day of the same stock or index.",
//     First_Trade_Lower_Range: "The minimum price level at which you want to initiate your first buying/selling position.",
//     First_Trade_Higher_Range: "The maximum price level at which you want to initiate your first buying/selling position.",
//     Lower_Range: "The minimum price level below which no new positions will be created & acts as an overall stop - loss in buying conditions.",

//     Higher_Range: " Maximum price level above which no new positions will be created & acts as an overall stop - loss in selling conditions.",
//     Step_up: "To systematically increase the quantity of re - entries in defined batches.",
//     Increment_Type: "How the quantity will increase after each step-up( ex: - Addition - wise, Multiplication - wise.",
//     Increment_Value: "To determine the rate of increase for quantities in your selected increment type.",
//     Strike_Type: "Used to select OTM & ITM strike of the selected index",
//     Depth_of_Strike: "If DOS = 1 then it will select 1 OTM of CE & PE",
//     Risk_Handle: "You can exit positions based on future-wise, leg - wise & through premium addition",
//     percent_of_ATM: "The entered % of ATM is calculated for OTM strike price.",
//     Premium_Range: "Directly provide the range of premium that you want to buy / sell",
//     Straddle_Width: "Used to select desired CE & PE based on width of premium."

// }


export const text = {
    Lower_Price: `Enter here lower price range in which you want to buy / sell stock. 
Used to take the trade when the stock comes above this range.`,

    Higher_Price: `Enter here higher price range in which you want to buy / sell stock. 
Used to take the trade when the stock comes below this range.`,

    Unique_Name: "When we want to take multiple entries for the same stock, use different Unique Name.",
    Trade_Count: "It is used to take multiple trades on the same day of the same stock or index.",
    
    First_Trade_Lower_Range: `The minimum price level at which you want to initiate 
your first buying/selling position.`,

    First_Trade_Higher_Range: `The maximum price level at which you want to initiate 
your first buying/selling position.`,

    Lower_Range: `The minimum price level below which no new positions will be created & 
acts as an overall stop-loss in buying conditions.`,

    Higher_Range: `Maximum price level above which no new positions will be created & 
acts as an overall stop-loss in selling conditions.`,

    Step_up: "To systematically increase the quantity of re-entries in defined batches.",
    Increment_Type: `How the quantity will increase after each step-up 
(ex: Addition-wise, Multiplication-wise).`,

    Increment_Value: "To determine the rate of increase for quantities in your selected increment type.",
    Strike_Type: "Used to select OTM & ITM strike of the selected index.",
    Depth_of_Strike: "If DOS = 1 then it will select 1 OTM of CE & PE.",
    Risk_Handle: `You can exit positions based on future-wise, leg-wise & 
through premium addition.`,

    percent_of_ATM: "The entered % of ATM is calculated for OTM strike price.",
    Premium_Range: "Directly provide the range of premium that you want to buy/sell.",
    Straddle_Width: "Used to select desired CE & PE based on width of premium."
};
