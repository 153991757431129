import React, { useEffect, useState } from 'react'
import FullDataTable from '../../../ExtraComponent/CommanDataTable'
import { GetAllTaskStatus, GetClientService, Get_All_Client_Logs, getStrategyType } from '../../CommonAPI/Admin'
import { columns3, columns2, columns1, columns } from './UserAllColumn'
import NoDataFound from '../../../ExtraComponent/NoDataFound'

const Pannel = () => {

    const [getPanleData, setPanleData] = useState({
        loading: true,
        data: [],
        data1: []
    })
    const [userName, setUserName] = useState('')
    const [getScript, setScript] = useState('')
    console.log("getScript", getScript);

    const [getActivity, setActivity] = useState('')
    const [gettaskStatus, setAllTaskStatus] = useState([])
    const [clientService, setClientService] = useState({ loading: true, data: [] });


    const [tableType, setTableType] = useState("MultiCondition");
    console.log("table type ka data", tableType);


    const [strategyType, setStrategyType] = useState([]);
    console.log("strategyType", strategyType);



    const AllTaskStatus = async () => {
        await GetAllTaskStatus()
            .then((response) => {
                if (response.Status) {
                    setAllTaskStatus(response.Taskstatus)
                }
                else {
                    setAllTaskStatus([])
                }
            })
            .catch((err) => {
                console.log("Error in finding the Task Status", err)
            })
    }

    const fetchStrategyType = async () => {
        try {
            const res = await getStrategyType();
            //   console.log("fetchStrategyType",res);

            if (res.Data) {
                setStrategyType(res.Data);
            }
        } catch (error) {
            console.log("Error in finding the strategy type", error);
        }
    };

    useState(() => {
        AllTaskStatus()
        fetchStrategyType()
    }, [])

    useEffect(() => {
        const fetchClientService = async () => {
            try {
                const response = await GetClientService();
                if (response.Status) {


                    setClientService({
                        loading: false,
                        data: response.Data
                    });
                } else {
                    setClientService({ loading: false, data: [] });
                }
            } catch (error) {
                console.log('Error in fetching client services', error);
            }
        };

        fetchClientService();
    }, []);

    useEffect(() => {

        if (!clientService.loading && clientService.data.length > 0) {
            setUserName(clientService.data[0].Username)
        }


        setScript('Scalping')

        if (gettaskStatus && gettaskStatus.length > 0) {
            setActivity(gettaskStatus[0])
        }

    }, [clientService, gettaskStatus])


    const getAllUserLogs = async () => {
        const data = { User: userName, Strategy: getScript, TaskStatus: getActivity }
        console.log("getScript, ", getScript)
        await Get_All_Client_Logs(data)

            .then((response) => {
                if (response.Status) {
                    setPanleData({
                        loading: false,
                        data: response.Data,
                        data1: getScript === "Scalping" ? response?.Multicondition : []
                    })
                }
                else {
                    setPanleData({
                        loading: false,
                        data: [],
                        data1: []
                    })
                }
            })
            .catch((err) => {
                console.log("Error in finding the user logs", err)
            })
    }

    useEffect(() => {
        if (getScript == "Scalping") {
            setTableType("MultiCondition");
        } else {
            setTableType("Scalping");
        }
    }, [getScript]);

    useEffect(() => {
        getAllUserLogs()
    }, [userName, getScript, getActivity])


    return (
        <>
            <div>
                <div className="col-sm-12 col-lg-12">
                    <div className="iq-card">
                        <div className="iq-card-header d-flex justify-content-between">
                            <div className="iq-header-title">
                                <h4 className="card-title">User Panel Logs</h4>
                            </div>
                        </div>
                        <div className="iq-card-body">
                            <div>
                                <div className="row">
                                   
                                    <div className={`form-group ${getScript !== "Scalping" ? "col-lg-4" : "col-lg-3"}`}>
                                        <label>Username</label>
                                        <select
                                            className="form-select my-2"
                                            required
                                            onChange={(e) => setUserName(e.target.value)}
                                            value={userName}
                                        >
                                            <option value="">Select Username</option>
                                            {clientService.data?.map((item, index) => (
                                                <option key={index} value={item.Username}>
                                                    {item.Username}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                   
                                    <div className={`form-group ${getScript !== "Scalping" ? "col-lg-4" : "col-lg-3"}`}>
                                        <label>Strategy</label>
                                        <select
                                            className="form-select my-2"
                                            required
                                            onChange={(e) => setScript(e.target.value)}
                                            value={getScript}
                                        >
                                            {strategyType.map((type, index) => (
                                                <option key={index} value={type}>
                                                    {type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                  
                                    {getScript === "Scalping" && (
                                        <div className={`form-group ${getScript !== "Scalping" ? "col-lg-4" : "col-lg-3"}`}>
                                            <div className="px-3">
                                                <label>Table Type</label>
                                                <select
                                                    className="form-select my-2"
                                                    required
                                                    onChange={(e) => setTableType(e.target.value)}
                                                    value={tableType}
                                                >
                                                    <option value="Scalping">Scalping</option>
                                                    <option value="MultiCondition">Multi Condition</option>
                                                </select>
                                            </div>
                                        </div>
                                    )}

                                   
                                    <div className={`form-group ${getScript !== "Scalping" ? "col-lg-4" : "col-lg-3"}`}>
                                        <label htmlFor="email">Task Status</label>
                                        <select
                                            className="form-select my-2"
                                            required
                                            onChange={(e) => setActivity(e.target.value)}
                                            value={getActivity}
                                        >
                                            {gettaskStatus?.map((item, index) => (
                                                <option value={item} key={index}>{item}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            
                            <div className="table-responsive">
                              
                                {tableType === "Scalping" ? (
                                    getPanleData?.data?.length > 0 ? (
                                        <FullDataTable
                                            columns={
                                                getScript === "Scalping"
                                                    ? columns()
                                                    : getScript === "Option Strategy"
                                                        ? columns1()
                                                        : getScript === "Pattern"
                                                            ? columns2()
                                                            : columns()
                                            }
                                            data={getPanleData.data}
                                            checkBox={false}
                                        />
                                    ) : (
                                        <NoDataFound />
                                    )
                                ) : (
                                    
                                    tableType === "MultiCondition" && getPanleData?.data1?.length > 0 ? (
                                        <>
                                            <h4 className="mt-3">Multi Condition</h4>
                                            <FullDataTable columns={columns3()} data={getPanleData.data1} checkBox={false} />
                                        </>
                                    ) : (
                                        <NoDataFound />
                                    )
                                )}
                            </div>

                        </div>

                    </div >
                </div >
            </div>
        </>
    )
}
export default Pannel

